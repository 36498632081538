.mini-cropper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	background-color: var(--black_color);

	.cropper {
		height: calc(100svh - 200px);
	}

	&__controls {
		display: flex;
		gap: 10px;
		padding: 0 var(--page_x_padding);

		.btn-base {
			width: inherit;
			flex: 0.5;
		}
	}
}
